<template>
  <div>
    <b-button
        v-ripple.400="ripple"
        @click="openModalEmit"
        v-b-modal="`modal-${name}`"
        :variant="variant"
        class="w-100"
    >
      <slot name="button"></slot>
    </b-button>

    <b-modal
        :id="`modal-${name}`"
        cancel-variant="danger"
        @hidden="resetFields"
        centered
        :hide-footer="hideFooter"
        :no-close-on-backdrop="backdropClose"
        :size="size"
        :title="modalTitle"
    >
      <slot name="modal-body"></slot>
      <slot name="modal-footer"></slot>
      <template #modal-footer>
        <b-button
            class="submit__button"
            :class="{'loading-modal':loading}"
            :variant="variant"
            :disabled="disableButton"
            @click="modalEmit"
        >
          <span class="text">{{ modalButton }}</span>
          <div class="loader">
            <b-spinner style="width: 1.5rem; height: 1.5rem;"/>
          </div>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BModal, BSpinner, VBModal} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ModalButton',
  components: {
    BButton,
    BModal,
    BSpinner
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    variant: {
      type: String,
      default: 'outline-success'
    },
    loading: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'modal',
    },
    ripple: {
      type: String,
      default: 'rgba(113, 102, 240, 0.15)'
    },
    modalTitle: {
      type: String,
      default: 'Title'
    },
    modalButton: {
      type: String,
      default: 'Accept'
    },
    size: {
      type: String,
      default: 'xl'
    },
    disableButton: {
      type: Boolean,
      default: () => false,
    },
    backdropClose: {
      type: Boolean,
      default: () => false,
    },
    hideFooter: {
      type: Boolean,
      default: () => false,
    }
  },
  methods: {
    modalEmit() {
      this.$emit('btn-emit', this.id)
    },
    openModalEmit() {
      this.$emit('open-emit', this.id)
    },
    resetFields() {
      this.$emit('open-emit')
    },
    closeModal() {
      this.$bvModal.hide(`modal-${this.name}`)
    },
  }

}
</script>

<style lang="scss">

[dir=ltr] .modal-header .close {
  margin: 0;
}

.submit__button {

  .loader {
    display: none;
  }

  &.loading-modal {
    position: relative;

    .text {
      opacity: 0;
    }

    .loader {
      display: flex;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }



}
</style>
